import styled from "styled-components"

import { maxDeviceSizeForMediaQueries } from "../../../../../styles/devices-medias"

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	margin-left: 280px;
	margin-top: 60px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.palette.background.default};
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		margin-left: 0px;
		padding: 16px;
		flex-direction: column;
		overflow: scroll;
	}
`
