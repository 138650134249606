import { createTheme } from "@mui/material/styles"
import { deDE } from "@mui/x-date-pickers/locales"

export const primaryTheme = createTheme(
	{
		palette: {
			primary: {
				main: "#309587",
				contrastText: "#ffffff",
			},
			secondary: {
				main: "#1f4077",
				contrastText: "#ffffff",
			},
			text: {
				primary: "#1f1f1f",
				secondary: "#1f1f1f",
			},
			background: {
				default: "#ffffff",
			},
		},
		components: {
			MuiFormControlLabel: {
				styleOverrides: {
					label: {
						color: "#1f1f1f",
					},
				},
			},
		},
	},
	deDE,
)

export const darkTheme = createTheme(
	{
		palette: {
			mode: "dark",
			primary: {
				main: "#6d7584",
				contrastText: "rgb(33,33,33)",
			},
			secondary: {
				main: "#ffffff",
			},
			text: {
				primary: "#ffffff",
				secondary: "#ffffff",
			},
			background: {
				default: "rgb(33, 33, 33)",
			},
		},
		components: {
			MuiFormControlLabel: {
				styleOverrides: {
					label: {
						color: "#ffffff",
					},
				},
			},
		},
	},
	deDE,
)
