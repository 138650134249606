import { enqueueSnackbar } from "notistack"
import { useState } from "react"
import { useForm } from "react-hook-form"

import { detailsApiKey } from "../../../apis/apiKey"
import { AuthStorage } from "../../../storage/auth-type"
import { setItem } from "../../../storage/local-storage"
import { useAuthentication } from "../useAuthentication"

export enum ApiKeyDetailsServiceRequestFieldType {
	STRING = "string",
	NUMBER = "number",
	BOOLEAN = "boolean",
	DATE = "date",
}

export type ApiKeyDetailsServiceRequestField = {
	id: string
	name: string
	example: string
	type: ApiKeyDetailsServiceRequestFieldType
	label?: string
	required: boolean
}

export enum ApiKeyDetailsServiceResponseFieldType {
	STRING = "string",
	NUMBER = "number",
	BOOLEAN = "boolean",
	DATE = "date",
}

export type ApiKeyDetailsServiceResponseField = {
	description: string
	path: string
	type: ApiKeyDetailsServiceResponseFieldType
	label?: string
	format?: string
	mapping?: object
}

export type ApiKeyDetailsServiceCustomResponseField = {
	customFaceId: string
}

export type ApiKeyDetailsService = {
	serviceLimitId: string
	serviceId: string
	name: string
	usageLimit: number
	unlimited: boolean
	freeUsageLimit: number
	url: string
	description: string
	requestFields: ApiKeyDetailsServiceRequestField[]
	responseFields: ApiKeyDetailsServiceResponseField[] | ApiKeyDetailsServiceCustomResponseField
}

export const useLabApi = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const [response, setResponse] = useState<any>(null)
	const [canExecute, setCanExecute] = useState<boolean>(false)
	const [apiKey, setApiKey] = useState<string>("")
	const [productId, setProductId] = useState<string>("")
	const [apiKeyInformation, setApiKeyInformation] = useState<any>()
	const [errorMessage, setErrorMessage] = useState<string>("")
	const [isError, setIsError] = useState<boolean>(false)
	const [snackbar, setSnackbar] = useState<boolean>(false)
	const [snackbarMessage, setSnackbarMessage] = useState<string>("")
	const [snackbarType, setSnackbarType] = useState<"success" | "error">("success")
	const [isCheckingApiKeyDetails, setIsCheckingApiKeyDetails] = useState<boolean>(false)
	const [services, setServices] = useState<ApiKeyDetailsService[]>([])
	const [selectedService, setSelectedService] = useState<ApiKeyDetailsService | null>()
	const [apiKeyDetails, setApiKeyDetails] = useState<any>()

	const form = useForm<any>()

	const { isAuthenticatedUser } = useAuthentication()

	const executeApi = async (params: {
		data?: any
		apiKey?: string
		extraHeaders?: any
		apiCallback: (values: any, url: string, apiKey?: string, extraHeaders?: any) => Promise<any>
	}) => {
		if (!selectedService) return
		setLoading(true)
		setResponse(null)
		setErrorMessage("")
		setIsError(false)
		const values = {
			...form.getValues(),
			...params.data,
		}
		try {
			const { data } = await params.apiCallback(
				values,
				selectedService?.url,
				params?.apiKey,
				params?.extraHeaders,
			)

			if (data?.Error) {
				setIsError(true)
				setErrorMessage(data?.Error.message)
			}
			setResponse(data)
		} catch (error: any) {
			const message =
				error?.response?.data?.messages?.join(", ") ||
				error?.response?.data?.message ||
				error?.response?.data?.error ||
				error?.response?.data?.Error?.message
			enqueueSnackbar(`Erro ao executar a API: ${message}`, {
				variant: "error",
			})
		} finally {
			setLoading(false)
		}
	}

	const executeFakeApi = (fakeResponses: []) => {
		setLoading(true)
		setTimeout(() => {
			setLoading(false)
			form.reset()
			const arrayPositionRandom = Math.floor(Math.random() * fakeResponses.length)
			setResponse(fakeResponses[arrayPositionRandom])
		}, 500)
	}

	const getDetailsApiKey = async (apiKeyParam?: string) => {
		try {
			if (!apiKey && !apiKeyParam) return
			setIsCheckingApiKeyDetails(true)
			const key = apiKeyParam || apiKey

			const { data } = await detailsApiKey(key)

			if (!isAuthenticatedUser) {
				enqueueSnackbar(`Chave de API verificada com sucesso!`, {
					variant: "success",
				})
			}

			setApiKeyDetails(data?.data)
			if (data?.data?.services.length) {
				const servicesApis = data?.data?.services
				setServices(servicesApis)
				setSelectedService(servicesApis[0])

				const serviceSelected = servicesApis.filter((el: any) => {
					return el.id === productId
				})

				if (serviceSelected.length) {
					setApiKeyInformation(serviceSelected[0])
					setCanExecute(!!serviceSelected[0].freeUsageLimit)
				} else {
					setCanExecute(false)
				}
			} else {
				setApiKeyInformation(null)
				setCanExecute(false)
				if (!isAuthenticatedUser) {
					enqueueSnackbar(`Chave de API não possui serviços disponíveis para consulta`, {
						variant: "warning",
					})
				}
			}
		} catch (error: any) {
			setApiKeyInformation(null)
			setCanExecute(false)
			setServices([])
			setSelectedService(undefined)
			setResponse(null)
			const errorMessage = error?.response?.data?.messages?.join(", ")
			setSnackbarMessage(`Erro ao buscar informações da chave de API: ${errorMessage}`)
			setSnackbar(true)
			setSnackbarType("error")
		} finally {
			setIsCheckingApiKeyDetails(false)
		}
	}

	const updateApiKey = (value: any) => {
		setApiKey(value)
		setItem(AuthStorage.userApiKey, { apiKey: value })
	}

	const onCloseResponseBox = () => {
		setResponse(null)
	}

	const showUsageAlert = () => {
		return !!selectedService
	}

	return {
		executeApi,
		executeFakeApi,
		getDetailsApiKey,
		updateApiKey,
		onCloseResponseBox,
		showUsageAlert,
		setApiKey,
		setProductId,
		setSnackbar,
		setIsError,
		setLoading,
		setResponse,
		setErrorMessage,
		setSelectedService,
		setServices,
		setSnackbarType,
		setSnackbarMessage,
		setApiKeyDetails,
		apiKeyDetails,
		snackbarMessage,
		snackbarType,
		selectedService,
		services,
		form,
		snackbar,
		apiKey,
		apiKeyInformation,
		canExecute,
		loading,
		productId,
		response,
		errorMessage,
		isError,
		isCheckingApiKeyDetails,
	}
}
