import { APIExecuterForm } from "./form"
import { APIExecuterFormAction } from "./form/action"
import { APIExecuterFormBody } from "./form/body"
import { APIExecuterFormField, APIExecuterCheckboxField } from "./form/field"
import { APIExecuterFormFieldWrapper } from "./form/field/wrapper"
import { APIExecuterFormHeader } from "./form/header"
import { APIExecuterNoServiceFound } from "./no-service-found"
import { APIExecuterRoot } from "./root"

export const APIExecuter = {
	Form: APIExecuterForm,
	Root: APIExecuterRoot,
	FormField: APIExecuterFormField,
	FormFieldCheck: APIExecuterCheckboxField,
	FormFieldWrapper: APIExecuterFormFieldWrapper,
	FormBody: APIExecuterFormBody,
	FormHeader: APIExecuterFormHeader,
	FormAction: APIExecuterFormAction,
	NoServiceFound: APIExecuterNoServiceFound,
}
