import {
	ConsultDeathParams,
	DateFraudParams,
	QuizFraudParams,
	InfoQualyCnpjParams,
	InfoQualyCpfParams,
	QuizFraudParamsResponse,
	RevalidatePrescriptionParams,
	logcomexParams,
} from "../models/Products"
import axios from "./config/axios-lab"

export const consultDeathCompleteApi = async (params: ConsultDeathParams) =>
	await axios.post("/completa", params)

export const sendRequest = async (
	params: any,
	url: string,
	apiKey?: string,
	extraHeaders?: any,
) => {
	axios.defaults.baseURL = url

	const headers: any = {}
	if (apiKey) headers.apikey = apiKey

	const response = await axios.post("", params, {
		headers: {
			...headers,
			...extraHeaders,
		},
	})
	return response
}

export const consultDeathApiSimpleApi = async (params: ConsultDeathParams, url: string) => {
	axios.defaults.baseURL = url

	const response = await axios.post("", params)
	return response
}

export const revalidatePrescriptionApi = async (
	params: RevalidatePrescriptionParams,
	url: string,
) => {
	axios.defaults.baseURL = url

	const response = await axios.post("", params)
	return response
}

export const quizFraudApi = async (params: QuizFraudParams, url: string) => {
	axios.defaults.baseURL = url

	const response = await axios.post("", params)
	return response
}

export const quizFraudApiResponse = async (params: QuizFraudParamsResponse, url: string) => {
	axios.defaults.baseURL = url

	const response = await axios.post("", { answer: params })
	return response
}

export const dateFraudApi = async (params: DateFraudParams, url: string) => {
	axios.defaults.baseURL = url

	const response = await axios.post("", params)
	return response
}

export const infoQualyApiCPF = async (params: InfoQualyCpfParams, url: string) => {
	axios.defaults.baseURL = url

	const response = await axios.post("", params)
	return response
}

export const infoQualyApiCNPJ = async (params: InfoQualyCnpjParams, url: string) => {
	axios.defaults.baseURL = url

	const response = await axios.post("", params)
	return response
}

export  const logcomex = async (params: logcomexParams, url: string) => {
	axios.defaults.baseURL = url

	const response = await axios.post("", params)
	return response
}