import { Control } from "react-hook-form"

import { ControlledTextField } from "../../../../../../components/text-field/controlled-text-field"
import { styles } from "../../../../../../styles/common"
import { ControlledCheckbox } from "../../../../../../components/checkbox/controlled-checkbox"

interface APIExecuterFormFieldProps {
	id: string
	label: string
	placeholder?: string
	isRequired: boolean
	control: Control
}

export const APIExecuterFormField = ({
	id,
	label,
	isRequired,
	placeholder,
	control,
}: APIExecuterFormFieldProps) => {
	return (
		<ControlledTextField
			id={id}
			style={styles.textFieldMarginTop}
			label={label}
			placeholder={placeholder ? `ex: ${placeholder}` : ""}
			control={control}
			rules={{ required: isRequired }}
		/>
	)
}

interface APIExecuterCheckboxFieldProps {
	id: string;
	label: string;
	control: Control;
}

export const APIExecuterCheckboxField = ({
	id,
	label,
	control,
}: APIExecuterCheckboxFieldProps) => {
	return (
		<ControlledCheckbox
			id={id}
			style={styles.textFieldMarginTop}
			label={label}
			control={control}
		/>
	);
};