import { RoleEnum } from "../pages/user/roles"

export const pathNames = {
	home: "/",
	docs: "/docs",
	lab: {
		consultDeath: "/lab/consult-death",
		infoQualyCPF: '/lab/Cadastral/CPF',
		logcomex: '/lab/logcomex',
		infoQualyApiCNPJ: '/lab/Cadastral/CNPJ',
		revalidatePrescription: "/lab/revalidate-prescription",
		dateFraud: "/lab/date-fraud",
		quiz: "/lab/quiz",
		root: "/lab",
	},
	auth: {
		signIn: "/signin",
		signOut: "/signout",
	},
	project: {
		list: "/projects",
		edit: "/projects/edit/",
		create: "/projects/create",
	},
	queries: {
		listBatch: "/queries/batch",
		createBatch: "/queries/batch/create",
	},
	service: {
		list: "/services",
		edit: "/services/edit/",
		create: "/services/create",
	},
	team: {
		list: "/teams",
		edit: "/teams/edit/",
		create: "/teams/create",
	},
	apiKey: {
		list: "/api-keys",
		edit: "/api-keys/edit/",
		create: "/api-keys/create",
	},
	users: {
		user: { list: "/users", edit: "/users/edit/", create: "/users/create" },
		logs: "/logs",
	},
	organization: {
		list: "/organizations",
		edit: "/organizations/edit/",
		create: "/organizations/create",
	},
	consumption: {
		dashboard: "/consumption",
		dataExtraction: "/consumption/extraction",
		invoicing: "/consumption/invoicing",
		invoicingDetails: "/consumption/invoicing/:id",
	},
	services: {
		list: "/services",
		advancedSearch: "/services/advancedSearch",
		updateObitoDatabase: "/services/update-obito-database",
		obitoDatabaseUpdate: {
			list: "/services/obito-database-updates",
			create: "/services/obito-database-updates/create",
		},
		edit: "/services/edit/",
	},
	settings: {
		ip_liberation: {
			list: "/ips",
			create: "/ips/create",
			edit: "/ips/edit/",
		},
		keys: {
			list: "/keys",
			edit: "/keys/edit",
		},
	},
	logs: "/logs",
	profile: "/profile",
	profileOrganization: "/profile-organization",
}

export const rootPathByRole = {
	[RoleEnum.Admin]: pathNames.consumption.dashboard,
	[RoleEnum.Manager]: pathNames.consumption.dashboard,
	[RoleEnum.Operator]: pathNames.queries.listBatch,
}
